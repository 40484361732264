<template>
    <div class="d-flex flex-wrap mb-5">
        <div class="col-12">
            <h1 class="text-primary">Bienvenido nuestro portal</h1>
        </div>
        <div class="col-12 mt-2">
            <p class="text-justify">
                Nos complace enormemente que formes parte de este proyecto.
                {{ $siteTitle }}
                es una plataforma completamente gratuita para la gestión de
                comercios, principalmente negocios y misceláneas. En nuestra
                plataforma, podrás :
            </p>
        </div>

        <div class="col-11 mx-auto">
            <ul class="list-group">
                <li class="py-2 px-1">Crear una o más tiendas.</li>
                <li class="py-2 px-1">
                    Dar acceso a tus empleados con diferentes roles. Proporciona
                    a tus empleados acceso para que puedan asociar y consultar
                    sus ventas, de manera que puedan calcular las comisiones
                    correspondientes.
                </li>
                <li class="py-2 px-1">
                    Registrar y administrar tu inventario de manera sencilla,
                    utilizando el código de barras, precio de venta y unidades.
                    Nosotros nos encargaremos de registrar los productos más
                    comunes en el mercado.
                </li>
                <li class="py-2 px-1">
                    Registrar tus ventas y llevar un registro del dinero en
                    caja.
                </li>
                <li class="py-2 px-1">
                    Crear servicios y registrarlos en tus ventas.
                </li>
                <li class="py-2 px-1">
                    Cargar los gastos de la tienda y adjuntar comprobantes de
                    pago.
                </li>
                <li class="py-2 px-1">
                    Crear listas de compras para tener una visión clara de los
                    productos que necesitas abastecer.
                </li>
                <li v-if="$enabledEcommerceOptions" class="py-2 px-1">
                    Opcionalmente, podrás habilitar tu tienda para que haga
                    entregas de pedidos en línea a través de nuestra página web
                    <a :href="$marketplaceUrl" target="_blank" rel="noopener">
                        {{ $marketplaceUrl }}
                    </a>
                    y recibir ingresos extra.
                </li>
            </ul>
        </div>
        <div class="col-12 mt-3">
            <p class="text-justify">
                Estamos seguros de que nuestra plataforma te ayudará a mejorar
                la gestión de tu negocio y esperamos que disfrutes utilizando
                todas las herramientas que tenemos para ofrecerte. Puedes
                continuar creando tu tienda.
            </p>
        </div>

        <div class="col-12 d-flex justify-content-center mt-3">
            <vs-button size="large" @click="$router.push('/onboarding/store')">
                Continuar
            </vs-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "OnboardingPageIndex",
    data: () => ({})
};
</script>
